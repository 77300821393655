<template>
	<div class="box">
		<div class="title">我的账号</div>
		<div class="company">
			<img v-if="showTrial" class="trial" src="../../assets/image/trial.png" >
			<img :src="createForm.customerLogo ? createForm.customerLogo:'https://www.zhongsituo.com/group1/M02/00/1A/wKgCGGGeDMCAOrQMAAACgE3M_6M2086047?filename=%E4%BC%81%E4%B8%9A.png'"
				class="logo" alt="" />
			<div class="name">{{ createForm.customerName }}</div>
			<div class="time1">
				剩余有效期：{{ timeDifference > 0 ? timeDifference : 0 }}天
			</div>
			<div class="time2">到期日期：{{ time }}</div>
			<div class="time3">用户管理员：{{ customerInfo.customerAdminName }}</div>
		</div>
		<div class="manager">
			<img :src="createForm.userLogo ? createForm.userLogo :'https://www.zhongsituo.com/group1/M02/00/04/wKgCGWGeGbiAZTJXAAADioyK4Y86093132?filename=%E5%A4%B4%E5%83%8F.png'"
				class="header" alt="" />
			<span class="nameBox">
				<div class="name">
					{{ `${createForm.nickname}` }}
				</div>
				<div class="type">
					{{type}}
				</div>
			</span>
			<div class="upload">
				<Button style="color: #1EAEF5;" type="text" size="small" @click="uploadLogo">更换头像</Button>
				<input id='btn_file' type="file" @change="changeImg" value="更换头像" size="small" />
			</div>
		</div>
		<div class="content">
			<Form :model="createForm" :label-width="100" inline class="create-form" :rules="createRules">
				<FormItem style='width: 45%;' label="姓名" prop="nickname">
					<Input v-model="createForm.nickname" disabled placeholder="请输入"></Input>
				</FormItem>
				<FormItem style='width: 45%;' label="性别" prop="sex">
					<RadioGroup v-model="createForm.sex">
						<Radio v-for="item in genderList" :label="item.value" :key="item.value">{{item.label}}
						</Radio>
					</RadioGroup>
				</FormItem>
				<FormItem style='width: 45%;' label="手机号码" prop="phone">
					<Input v-model="createForm.phone" disabled style="width:100%"></Input>
				</FormItem>
				<FormItem style='width: 45%;' label="密码" prop="password">
					<Input v-model="createForm.password" type="password" disabled placeholder="******"
						style="width: 77%; margin-right:3%"></Input>
					<Button type="info" style="width: 20%;" @click="showModal = true">重置</Button>
				</FormItem>
				<FormItem style='width: 45%;' label="职务/部门" prop="userDepartment">
					<Input v-model="createForm.userDepartment" placeholder="请输入"></Input>
				</FormItem>
				<FormItem style='width: 45%;' label="出生日期" prop="birthday">
					<DatePicker :editable='false' style='width: 100%;' v-model="birthday" @on-change='changeBirthday'
						type="date" placeholder="选择出生日期"></DatePicker>
				</FormItem>

				<FormItem style='width: 45%;' label="邮箱" prop="email">
					<Input v-model="createForm.email" placeholder="请输入"></Input>
				</FormItem>

				<FormItem style='width: 45%;' label="微信" prop="userWechat">
					<Input v-model="createForm.userWechat" placeholder="请输入"></Input>
				</FormItem>
				<FormItem style='width: 45%;' prop="userWechat">
					<Button type="info" style="width: 140px;height: 40px;" @click="save">保存</Button>
				</FormItem>

				<!-- 	<div style="text-align: left">
					<Button type="primary" style="width: 144px" @click="save">保存</Button>
				</div> -->
			</Form>
		</div>

		<Modal v-model="showModal" class-name="vertical-center-modal" title='修改密码' footer-hide
			@on-cancel="cancelModalHandle" width="416">
			<!-- 			<p slot="header" style="
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          display: flex;
          align-item: center;
        " class="modalHeader">
				<img src="../../assets/image/title-left-icon.png" style="width: 10px; height: 23px; margin-right: 6px"
					alt="" />修改密码
			</p> -->
			<div class="modalContent">
				<Form :model="formItem" :label-width="90">
					<FormItem label="原密码">
						<Input v-model="formItem.lastpsw" password type="password" placeholder="原密码"></Input>
					</FormItem>
					<FormItem label="新密码">
						<Input v-model="formItem.psw" password type="password" placeholder="新密码"></Input>
					</FormItem>
					<FormItem label="确认新密码">
						<Input v-model="formItem.psw2" password type="password" placeholder="确认新密码"></Input>
					</FormItem>
				</Form>
				<div style="width: 141px; margin: 40px auto">
					<Button :loading="loading" @click.native.prevent="handleSetPsw" type="info"
						style="width: 140px;height: 40px;" class="setPsw" circle>确定</Button>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script>
	import {
		getDetail,
		repetition,
		update,
		updatepsw,
		getverify,
	} from "@/api/MyAccount";
	import {
		mapMutations,
		mapState
	} from "vuex";
	import {
		formatTime,
		formatDate
	} from "@/utils/index";
	export default {
		data() {
			return {
				showModal: false,
				birthday: '',
				createForm: {
					email: "",
					gmtCreate: 0,
					id: 1,
					nickname: "",
					phone: "",
					pidName: "",
					sex: "",
					userAduiltResult: "",
					userCreater: "",
					userDepartment: "",
					userType: "",
					userWechat: "",
					userWorktime: "",
					username: "",
					state: "",
					birthday: '',
				},
				userTypeArr: [{
						userType: 1,
						userTypeName: "用户管理员",
					},
					{
						userType: 2,
						userTypeName: "普通用户",
					},
					{
						userType: 3,
						userTypeName: "独立管理员",
					},
				],
				loading: false,
				loading2: false,
				formItem: {
					lastpsw: "",
					psw: "",
					psw2: "",
					code: "",
				},
				genderList: [{
						value: "0",
						label: "男",
					},
					{
						value: "1",
						label: "女",
					},
				],
				createRules: {
					nickname: [{
						required: false,
						message: "此项必填",
						trigger: "blur"
					}],
					password: [{
						required: false,
						message: "此项必填",
						trigger: "blur"
					}],
					username: [{
						required: false,
						message: "此项必填",
						trigger: "blur"
					}],
					sex: [{
						required: false,
						message: "此项必填",
						trigger: "blur"
					}],
					userDepartment: [{
						required: false,
						message: "此项必填",
						trigger: "blur"
					}, ],
					userWorktime: [{
						required: false,
						message: "此项必填",
						trigger: "blur"
					}, ],
					phone: [{
						required: false,
						message: "此项必填",
						trigger: "blur"
					}],
					status: [{
						required: false,
						message: "此项必填",
						trigger: "blur"
					}],
				},
				sec: 0,
			};
		},
		computed: {
			...mapState(["userId", "user"]),
			time() {
				return formatDate(this.createForm.customerAccountEndDate);
			},
			timeDifference() {
				return parseInt(
					(new Date(this.createForm.customerAccountEndDate).getTime() -
						new Date().getTime()) /
					1000 /
					(24 * 60 * 60)
				);
			},
			type() {
				let usr = this.userTypeArr.filter((i) => {
					return i.userType == this.createForm.userType;
				});
				return usr[0].userTypeName;
			},
			customerInfo(){
				return JSON.parse(localStorage.getItem('customerInfo'))
			},
			showTrial(){
				let state = false
				if(this.customerInfo.customerState == 0 || this.customerInfo.customerState == 3){
					state = true
				}
				return state
			}

		},
		methods: {
			...mapMutations(["changeState"]),
			changeBirthday(e) {
				this.createForm.birthday = e
			},
			uploadLogo() {
				document.getElementById("btn_file").click()
			},
			sendCode() {
				this.loading2 = true;
				this.timer = setInterval(() => {
					if (this.sec > 1) {
						this.sec--;
					} else {
						this.sec = 60;
						clearInterval(this.timer);
					}
				}, 1000);
				getverify({
						username: this.createForm.username,
					})
					.then((res) => {
						this.loading2 = false;
						return this.$msg.success({
							text: "验证码已发送"
						});
						// this.$msg.success("验证码已发送");
					})
					.catch((err) => {
						this.loading2 = false;
						this.$msg.error({
							text: err.data
						});
					});
			},
			checkRepetition() {
				if (!this.createForm.nickname) {
					return this.$msg.error({
						text: "请输入用户账号"
					});
					// return this.$msg.error("请输入用户账号");
				}
				repetition({
					username: this.createForm.nickname,
				}).then((res) => {
					// this.$msg.success("此账号可使用")
					this.$msg.success({
						text: "此账号可使用"
					});
				});
			},
			save() {
				let params = {
					email: this.createForm.email,
					id: this.userId,
					userWechat: this.createForm.userWechat,
					userLogo: this.createForm.userLogo,
					sex: this.createForm.sex,
					birthday: this.createForm.birthday,
					userDepartment: this.createForm.userDepartment
				}
				update(params).then((res) => {
						// this.$msg.success("修改成功");
						this.$msg.success({
							text: "修改成功"
						});
						this.changeState({
							prop: "user",
							value: Object.assign(this.user, {
								userLogo: this.createForm.userLogo,
							}),
						});
					})
					.catch((err) => {
						this.$msg.error({
							text: err.data
						});
					});
			},
			init() {
				getDetail(this.userId).then((res) => {
					this.createForm = res;
					this.birthday = this.createForm.birthday ? this.createForm.birthday : ''
				});
			},
			blobToDataURL(blob, cb) {
				let reader = new FileReader();
				reader.onload = function(evt) {
					var base64 = evt.target.result;
					cb(base64);
				};
				reader.readAsDataURL(blob);
			},
			changeImg(event) {
				let img = event.target.files[0];
				let that = this;
				if (img) {
					var url = URL.createObjectURL(img);
					var base64 = this.blobToDataURL(img, function(base64Url) {
						that.createForm.userLogo = base64Url;
					});
				}
			},
			handleSetPsw() {
				if (!this.formItem.lastpsw.trim())
					return this.$msg.error({
						text: "请输入原密码"
					});
				if (!this.formItem.psw.trim())
					return this.$msg.error({
						text: "请输入新密码"
					});
				if (this.formItem.psw.trim() !== this.formItem.psw2.trim())
					return this.$msg.error({
						text: ["两次输入的新密码", "不一致", "，请重新输入。"],
					});
				// if (!this.formItem.code.trim())
				// 	return this.$msg.success({
				// 		text: "请输入验证码"
				// 	});
				updatepsw({
						userId: this.userId,
						rawPassword: this.formItem.lastpsw.trim(),
						newPassword: this.formItem.psw.trim(),
						// verifyCode: this.formItem.code.trim(),
					})
					.then((res) => {
						// this.$msg.success("修改成功")

						this.$msg.success({
							text: "密码重置成功，请重新登录。"
						});
						setTimeout(() => {
							this.changeState({
								prop: "token",
								value: "",
							});
							localStorage.setItem("token", "");
							localStorage.setItem("isLogin", false);
							localStorage.setItem("userId", "");
							window.location.reload();
						}, 3000);
					})
					.catch((err) => {
						this.$msg.error({
							text: err.data
						});
					});
			},
			cancelModalHandle() {
				this.formItem.psw = "";
				this.formItem.psw2 = "";
			},
		},
		mounted() {
			this.init();
		},
	};
</script>

<style scoped lang="scss">
	.box {
		width: 100%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;

		.title {
			font-size: 24px;
			font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
			font-weight: bold;
			color: rgba(51, 51, 51, 1);
			border-bottom: 1px solid #d6d7d9;
			height: 50px;
			line-height: 30px;
		}

		.company {
			width: 100%;
			position: relative;
			margin-top: 20px;
			height: 154px;
			background: rgba($color: #999999, $alpha: 0.1);
			border-radius: 8px;
			.trial{
				position: absolute;
				top: -53px;
				left: -49px;
			}

			.logo {
				width: 60px;
				height: 60px;
				display: block;
				position: absolute;
				top: 45px;
				left: 106px;
				background: #eee;
			}

			.name {
				height: 21px;
				font-size: 18px;
				font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
				font-weight: bold;
				color: #333333;
				line-height: 21px;
				position: absolute;
				top: 50px;
				left: 200px;
			}

			.time1,
			.time2,
			.time3 {
				height: 19px;
				font-size: 14px;
				font-family: MicrosoftYaHei;
				color: rgba(153, 153, 153, 1);
				line-height: 19px;
				position: absolute;
				top: 80px;
			}

			.time1 {
				left: 200px;
			}

			.time2 {
				left: 400px;
			}

			.time3 {
				left: 620px;
			}
		}

		.manager {
			margin-top: 40px;
			padding: 0 100px;

			.header {
				width: 60px;
				height: 60px;
				background: #eee;
				margin-right: 10px;
				margin-left: 6px;
			}

			.nameBox {
				display: inline-block;
				font-size: 24px;
				font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
				font-weight: bold;
				color: rgba(51, 51, 51, 1);
				margin-right: 10px;
				margin-left: 10px;

				.type {
					font-size: 14px;
					font-weight: 400;
					padding: 4px;
					border-radius: 8px;
					color: #999999;
					background-color: rgba($color: #999999, $alpha: 0.1);
					width: auto;
					text-align: center;
					display: inline-block;
				}
			}

			.upload {
				position: relative;
				overflow: hidden;

				input {
					/* position: absolute; */
					/* 					left: 0;
					top: 0; */
					opacity: 0;
					pointer-events: none;
					cursor: pointer;
				}
			}
		}

		.content {
			padding: 30px 70px;

			.create-form {
				width: 100%;

				.row {
					width: 100%;
					height: 30px;
					margin-bottom: 20px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.col {
						flex: 1;
					}
				}
			}
		}
	}

	/deep/.ivu-input[disabled],
	fieldset[disabled] .ivu-input {
		color: #333333;
	}
	/deep/.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.ivu-modal {
			top: 0;
		}
	}


</style>
